import Select from 'react-select'
import Creatable from 'react-select/creatable'

export const Dropdown = (props: {
    options: string[]
    onSelect?: (option?: string) => void
    onMultiSelect?: (option?: string[]) => void
    placeholder?: string
    defaultValue?: string
    value?: string | string[]
    search?: boolean
    clear?: boolean
    creatable?: boolean
    multiple?: boolean
}) => {
    const {
        options,
        search,
        clear,
        creatable,
        multiple,
        defaultValue,
        value,
        placeholder,
        onSelect,
        onMultiSelect,
    } = props
    const dropdownProps = {
        styles: {
            control: (provided: any, state: any) => ({
                ...provided,
                minHeight: '2rem',
                fontSize: '12px',
                backgroundColor: 'var(--dark3)',
                border: state.isFocused ? 'none' : 'none',
                boxShadow: state.isFocused ? 'none' : 'none', // Remove the blue border on focus
                fontFamily: 'var(--font-family)',
                '&:hover': {
                    border: state.isFocused ? 'none' : 'none',
                },
            }),
            valueContainer: (provided: any) => ({
                ...provided,
                fontSize: '12px',
                height: '2rem',
                padding: '0 6px',
            }),
            input: (provided: any) => ({
                ...provided,
                width: '8rem',
                height: '2rem',
                fontSize: '12px',
                margin: '0px',
                color: 'var(--text1)',
                padding: 0,
            }),
            indicatorsContainer: (provided: any) => ({
                ...provided,
                height: '2rem',
                fontSize: '12px',
            }),
            dropdownIndicator: (provided: any) => ({
                ...provided,
                paddingLeft: 0,
                color: 'var(--text1)',
                '&:hover': {
                    color: 'var(--text1)',
                },
            }),
            clearIndicator: (provided: any) => ({
                ...provided,
                paddingLeft: 0,
                color: 'var(--text1)',
                '&:hover': {
                    color: 'var(--text1)',
                },
            }),
            indicatorSeparator: () => ({
                display: 'none',
            }),
            menu: (provided: any) => ({
                ...provided,
                position: 'absolute',
                right: 0,
                width: 'max-content',
                minWidth: '100%',
                backgroundColor: 'var(--dark2)',
                fontFamily: 'var(--font-family)',
                border: '1px solid var(--dark3)',
                boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.4)',
            }),
            option: (provided: any) => ({
                ...provided,
                fontSize: '12px',
                color: 'var(--text1)',
                backgroundColor: 'var(--dark2)',
                fontFamily: 'var(--font-family)',
                '&:hover': {
                    backgroundColor: 'var(--dark1)',
                },
            }),
            singleValue: (provided: any) => ({
                ...provided,
                color: 'var(--text1)',
                fontFamily: 'var(--font-family)',
            }),
            noOptionsMessage: (provided: any) => ({
                ...provided,
                textAlign: 'center',
                fontSize: '12px',
                fontFamily: 'var(--font-family)',
            }),
            multiValue: (provided: any) => ({
                ...provided,
                backgroundColor: 'var(--dark2)',
            }),
            multiValueLabel: (provided: any) => ({
                ...provided,
                color: 'var(--text1)',
            }),
            multiValueRemove: (provided: any) => ({
                ...provided,
                color: 'var(--text1)',
                ':hover': {
                    backgroundColor: 'var(--dark3)',
                    color: 'white',
                    cursor: 'pointer',
                },
            }),
        },
        placeholder,
        value: value
            ? multiple
                ? (value as string[]).map((v) => ({ value: v, label: v }))
                : { value: value, label: value }
            : undefined,
        defaultValue: defaultValue
            ? { value: defaultValue, label: defaultValue }
            : undefined,
        isClearable: !!clear,
        isSearchable: !!search,
        isMulti: !!multiple,
        onChange: (option: any) =>
            multiple
                ? onMultiSelect &&
                  onMultiSelect(option.map((o: any) => o.value))
                : onSelect && onSelect(option?.value),
        options: options.map((a) => ({ value: a, label: a })),
    }
    return creatable ? (
        <Creatable {...dropdownProps} />
    ) : (
        <Select {...dropdownProps} key={`${value}`} />
    )
}
