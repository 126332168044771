import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Context } from '../../utils/context'
import styles from '../../App.module.css'
import {
    MdOutlineEdit,
    MdOutlineDelete,
    MdPlayArrow,
    MdOutlinePause,
} from 'react-icons/md'
import {
    deleteAccount,
    getAccountsMetadata,
    reactivateAllAccounts,
    toggleAccountStatus,
} from '../../services/api'
import Loader from 'react-spinners/BounceLoader'
import { RiVerifiedBadgeLine } from 'react-icons/ri'
import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi'
import { TbEye, TbEyeOff, TbEyeSearch } from 'react-icons/tb'
import { AccountModal } from './views/AccountModal'
import { AccountActivityModal } from './views/AccountActivityModal'
import { AccountDownloadModal } from './views/AccountDownloadModal'
import { EditAccount } from './views/EditAccount'
import { getAccountName, formatNumber } from '../bets/helpers'
import {
    SUPPORTED_BET_TYPES,
    SUPPORTED_BET_INTERVALS,
    SUPPORTED_BET_LEAGUES,
    supportedProxyCities,
    formatDownload,
} from './helpers'
import { AccountWithMetadataIF, AccountIF, ACCOUNT_STATUS } from './types'

export const Accounts = () => {
    const { accounts: baseAccounts } = useContext(Context)
    const [accounts, setAccounts] = useState<AccountWithMetadataIF[]>([])
    const [accountToEdit, setAccountToEdit] = useState<AccountIF | null>(null)
    const [accountModalAction, setAccountModalAction] = useState<{
        account: AccountWithMetadataIF
        action: 'DELETE' | 'VIEW_LOGS'
    } | null>(null)
    const [showAccountsDownloadModal, setShowAccountDownModal] =
        useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false)
    const [sort, setSort] = useState<{
        key:
            | 'status'
            | 'provider'
            | 'website'
            | 'source'
            | 'subsource'
            | 'username'
            | 'autobet'
            | 'lastCompletedBet'
            | 'lastAttemptedBet'
        order: number
    }>({ key: 'status', order: 1 })
    const [showArchived, setShowArchived] = useState(false)
    const [search, setSearch] = useState('')

    useEffect(() => {
        ;(async () => {
            setIsLoading(true)
            const accountsMetadata = await getAccountsMetadata()
            const accountsMetadataDict = new Map(
                accountsMetadata
                    ? accountsMetadata.map((a) => [String(a.id), a])
                    : []
            )
            setAccounts(
                baseAccounts.map((a) => {
                    const metadata = accountsMetadataDict.get(String(a.id))
                    return {
                        ...a,
                        activity: metadata?.activity ?? [],
                        lastCompletedBet: metadata?.lastCompletedBet,
                        lastAttemptedBet: metadata?.lastAttemptedBet,
                    }
                })
            )
            setIsLoading(false)
        })()
    }, [baseAccounts])

    const accountsToShow = useMemo(() => {
        return accounts
            ? accounts.filter((a) => {
                  if (!showArchived && a.status === ACCOUNT_STATUS.ARCHIVED)
                      return false
                  if (search === '') return true
                  const regex = new RegExp(`^${search}$`, 'i')
                  return [
                      a.website,
                      a.accountProviders
                          .map((ap) => ap.provider.name)
                          .join(', '),
                      a.source ?? '',
                      a.username,
                      a.password,
                      a.family ?? '',
                      a.proxyCity ?? '',
                      a.proxyState ?? '',
                      a.liability?.toString() ?? '',
                      a.autoBetAmount.toString(),
                      a.tags.map((tag) => tag).join(', '),
                      a.currency ?? '',
                  ].some((x) => regex.test(x))
              })
            : undefined
    }, [accounts, search, showArchived])

    const onConfirmDeleteAccount = useCallback(async (id: number) => {
        setIsLoading(true)
        await deleteAccount(id)
        setAccountModalAction(null)
        setIsLoading(false)
    }, [])

    const sortArrows = useCallback((order: number, show: boolean) => {
        return order > 0 ? (
            <BiDownArrowAlt
                size={16}
                style={{
                    opacity: show ? '1' : '0',
                }}
            />
        ) : (
            <BiUpArrowAlt
                size={16}
                style={{
                    opacity: show ? '1' : '0',
                }}
            />
        )
    }, [])

    const getAccountBetTypes = useCallback(
        (betTypes: string[], betIntervals: string[], betLeagues: string[]) => {
            if (
                betTypes.length === SUPPORTED_BET_TYPES.length &&
                betIntervals.length === SUPPORTED_BET_INTERVALS.length &&
                betLeagues.length === SUPPORTED_BET_LEAGUES.length
            )
                return 'All'
            let arr = []
            if (betTypes.length === SUPPORTED_BET_TYPES.length)
                arr.push('All Types')
            else if (betTypes.length === 1) arr.push(`Only ${betTypes[0]}`)
            else if (betTypes.length > 1)
                arr.push(
                    `No ${SUPPORTED_BET_TYPES.filter(
                        (t) => !betTypes.includes(t)
                    ).join(', ')}`
                )

            if (betIntervals.length === SUPPORTED_BET_INTERVALS.length)
                arr.push('All Intervals')
            else if (betIntervals.length === 1)
                arr.push(`Only ${betIntervals[0]}`)
            else if (betIntervals.length > 1)
                arr.push(
                    `No ${SUPPORTED_BET_INTERVALS.filter(
                        (t) => !betIntervals.includes(t)
                    ).join(', ')}`
                )

            if (betLeagues.length === SUPPORTED_BET_LEAGUES.length)
                arr.push('All Leagues')
            else if (betLeagues.length === 1) arr.push(`Only ${betLeagues[0]}`)
            else if (betLeagues.length > 1)
                arr.push(
                    `No ${SUPPORTED_BET_LEAGUES.filter(
                        (t) => !betLeagues.includes(t)
                    ).join(', ')}`
                )

            return arr.join(', ')
        },
        []
    )

    return (
        <div className={styles.settings}>
            <div className={styles.header}>
                <header>
                    Accounts {accountsToShow && `(${accountsToShow.length})`}
                </header>
                {accountToEdit === null && (
                    <input
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search..."
                    />
                )}
            </div>
            {accountToEdit === null ? (
                <div className={styles.section}>
                    <div className={styles.section_header}>
                        <div className={styles.title_container}>
                            Account Management
                            {showArchived ? (
                                <TbEyeOff
                                    title="Hide Archived Accounts"
                                    className={styles.icon}
                                    size={18}
                                    onClick={() => setShowArchived(false)}
                                />
                            ) : (
                                <TbEye
                                    title="Show Archived Accounts"
                                    className={styles.icon}
                                    size={18}
                                    onClick={() => setShowArchived(true)}
                                />
                            )}
                        </div>
                        <div className={styles.account_button_group}>
                            {accounts.filter(
                                (x) => x.status === ACCOUNT_STATUS.LOGIN_ERROR
                            ).length > 0 && (
                                <button
                                    style={{
                                        backgroundColor: 'green',
                                    }}
                                    onMouseDown={() => reactivateAllAccounts()}
                                >
                                    Reactivate Accounts
                                </button>
                            )}
                            <button
                                onMouseDown={() =>
                                    setAccountToEdit({
                                        autoBetAmount: 0,
                                    } as AccountIF)
                                }
                            >
                                Add Account
                            </button>
                            <button
                                onMouseDown={() =>
                                    setShowAccountDownModal(true)
                                }
                                disabled={
                                    !accountsToShow ||
                                    accountsToShow.length === 0
                                }
                            >
                                Download Accounts
                            </button>
                        </div>
                    </div>
                    <div className={styles.section_content}>
                        {isLoading || !accountsToShow ? (
                            <div className={styles.loading}>
                                <Loader
                                    loading={isLoading}
                                    size={48}
                                    color={'var(--dark1)'}
                                />
                            </div>
                        ) : accountsToShow && accountsToShow.length > 0 ? (
                            <div className={styles.table_container}>
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <td
                                                className={styles.sortable}
                                                onClick={() =>
                                                    sort.key === 'status'
                                                        ? setSort({
                                                              ...sort,
                                                              order:
                                                                  sort.order *
                                                                  -1,
                                                          })
                                                        : setSort({
                                                              key: 'status',
                                                              order: 1,
                                                          })
                                                }
                                            >
                                                <span
                                                    className={styles.sortable}
                                                    style={{ width: '20px' }}
                                                ></span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key === 'status'
                                                )}
                                            </td>
                                            <td
                                                className={styles.sortable}
                                                onClick={() =>
                                                    sort.key === 'website'
                                                        ? setSort({
                                                              ...sort,
                                                              order:
                                                                  sort.order *
                                                                  -1,
                                                          })
                                                        : setSort({
                                                              key: 'website',
                                                              order: 1,
                                                          })
                                                }
                                            >
                                                <span>Site</span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key === 'website'
                                                )}
                                            </td>
                                            <td>
                                                <span>Provider</span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key === 'provider'
                                                )}
                                            </td>
                                            <td
                                                className={styles.sortable}
                                                onClick={() =>
                                                    sort.key === 'source'
                                                        ? setSort({
                                                              ...sort,
                                                              order:
                                                                  sort.order *
                                                                  -1,
                                                          })
                                                        : setSort({
                                                              key: 'source',
                                                              order: 1,
                                                          })
                                                }
                                            >
                                                <span>Source</span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key === 'source'
                                                )}
                                            </td>
                                            <td
                                                className={styles.sortable}
                                                onClick={() =>
                                                    sort.key === 'subsource'
                                                        ? setSort({
                                                              ...sort,
                                                              order:
                                                                  sort.order *
                                                                  -1,
                                                          })
                                                        : setSort({
                                                              key: 'subsource',
                                                              order: 1,
                                                          })
                                                }
                                            >
                                                <span>Sub-Source</span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key === 'subsource'
                                                )}
                                            </td>
                                            <td
                                                className={styles.sortable}
                                                onClick={() =>
                                                    sort.key === 'username'
                                                        ? setSort({
                                                              ...sort,
                                                              order:
                                                                  sort.order *
                                                                  -1,
                                                          })
                                                        : setSort({
                                                              key: 'username',
                                                              order: 1,
                                                          })
                                                }
                                            >
                                                <span>Username</span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key === 'username'
                                                )}
                                            </td>
                                            <td>Password</td>
                                            <td>Family</td>
                                            <td>City</td>
                                            <td>Liability</td>
                                            <td
                                                className={styles.sortable}
                                                onClick={() =>
                                                    sort.key === 'autobet'
                                                        ? setSort({
                                                              ...sort,
                                                              order:
                                                                  sort.order *
                                                                  -1,
                                                          })
                                                        : setSort({
                                                              key: 'autobet',
                                                              order: 1,
                                                          })
                                                }
                                            >
                                                <span>AutoBet</span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key === 'autobet'
                                                )}
                                            </td>
                                            <td>Max Win</td>
                                            <td>Bet Type</td>
                                            <td>Rebet</td>
                                            <td>Tags</td>
                                            <td>Currency</td>
                                            <td
                                                className={styles.sortable}
                                                onClick={() =>
                                                    sort.key ===
                                                    'lastCompletedBet'
                                                        ? setSort({
                                                              ...sort,
                                                              order:
                                                                  sort.order *
                                                                  -1,
                                                          })
                                                        : setSort({
                                                              key: 'lastCompletedBet',
                                                              order: 1,
                                                          })
                                                }
                                            >
                                                <span>Last Completed Bet</span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key ===
                                                        'lastCompletedBet'
                                                )}
                                            </td>
                                            <td
                                                className={styles.sortable}
                                                onClick={() =>
                                                    sort.key ===
                                                    'lastAttemptedBet'
                                                        ? setSort({
                                                              ...sort,
                                                              order:
                                                                  sort.order *
                                                                  -1,
                                                          })
                                                        : setSort({
                                                              key: 'lastAttemptedBet',
                                                              order: 1,
                                                          })
                                                }
                                            >
                                                <span>Last Attempted Bet</span>
                                                {sortArrows(
                                                    sort.order,
                                                    sort.key ===
                                                        'lastAttemptedBet'
                                                )}
                                            </td>
                                            <td className={styles.sticky}></td>
                                        </tr>
                                    </thead>
                                    <tbody className={styles.scrollable}>
                                        {accountsToShow
                                            .sort((a, b) => {
                                                switch (sort.key) {
                                                    case 'status':
                                                        const statusOrder = {
                                                            [ACCOUNT_STATUS.ACTIVE]: 1,
                                                            [ACCOUNT_STATUS.INACTIVE]: 2,
                                                            [ACCOUNT_STATUS.DISABLED_MAX_WIN]: 3,
                                                            [ACCOUNT_STATUS.LOGIN_ERROR]: 4,
                                                            [ACCOUNT_STATUS.ERROR]: 5,
                                                            [ACCOUNT_STATUS.ARCHIVED]: 6,
                                                        }
                                                        const order = (
                                                            account: AccountIF
                                                        ) => {
                                                            if (
                                                                account.isScraperAccount
                                                            )
                                                                return 7
                                                            return statusOrder[
                                                                account.status
                                                            ]
                                                        }
                                                        return (
                                                            (order(a) -
                                                                order(b)) *
                                                            sort.order
                                                        )
                                                    case 'website':
                                                        return (
                                                            getAccountName(
                                                                a.website
                                                            ).localeCompare(
                                                                getAccountName(
                                                                    b.website
                                                                )
                                                            ) * sort.order
                                                        )
                                                    case 'source':
                                                        return (
                                                            (
                                                                a.source || ''
                                                            ).localeCompare(
                                                                b.source || ''
                                                            ) * sort.order
                                                        )
                                                    case 'subsource':
                                                        return (
                                                            (
                                                                a.subSource ||
                                                                ''
                                                            ).localeCompare(
                                                                b.subSource ||
                                                                    ''
                                                            ) * sort.order
                                                        )
                                                    case 'username':
                                                        return a.username &&
                                                            b.username
                                                            ? a.username.localeCompare(
                                                                  b.username
                                                              ) * sort.order
                                                            : 0
                                                    case 'autobet':
                                                        return a.autoBetAmount &&
                                                            b.autoBetAmount
                                                            ? a.autoBetAmount
                                                                  .toString()
                                                                  .localeCompare(
                                                                      b.autoBetAmount.toString()
                                                                  ) * sort.order
                                                            : 0
                                                    case 'lastCompletedBet':
                                                        if (
                                                            a.lastCompletedBet &&
                                                            b.lastCompletedBet
                                                        ) {
                                                            const dateA =
                                                                new Date(
                                                                    a.lastCompletedBet
                                                                )
                                                            const dateB =
                                                                new Date(
                                                                    b.lastCompletedBet
                                                                )
                                                            return (
                                                                dateA.getTime() -
                                                                dateB.getTime() *
                                                                    sort.order
                                                            )
                                                        } else if (
                                                            a.lastCompletedBet
                                                        ) {
                                                            return (
                                                                -1 * sort.order
                                                            )
                                                        } else if (
                                                            b.lastCompletedBet
                                                        ) {
                                                            return (
                                                                1 * sort.order
                                                            )
                                                        } else {
                                                            return 0
                                                        }
                                                    case 'lastAttemptedBet':
                                                        if (
                                                            a.lastAttemptedBet &&
                                                            b.lastAttemptedBet
                                                        ) {
                                                            const dateA =
                                                                new Date(
                                                                    a.lastAttemptedBet
                                                                )
                                                            const dateB =
                                                                new Date(
                                                                    b.lastAttemptedBet
                                                                )
                                                            return (
                                                                dateA.getTime() -
                                                                dateB.getTime() *
                                                                    sort.order
                                                            )
                                                        } else if (
                                                            a.lastAttemptedBet
                                                        ) {
                                                            return (
                                                                -1 * sort.order
                                                            )
                                                        } else if (
                                                            b.lastAttemptedBet
                                                        ) {
                                                            return (
                                                                1 * sort.order
                                                            )
                                                        } else {
                                                            return 0
                                                        }
                                                    default:
                                                        return 0
                                                }
                                            })
                                            .map((a) => (
                                                <tr key={a.id}>
                                                    <td
                                                        className={styles.icons}
                                                    >
                                                        {!a.isScraperAccount && (
                                                            <RiVerifiedBadgeLine
                                                                title={a.status}
                                                                color={
                                                                    a.status ===
                                                                    ACCOUNT_STATUS.ACTIVE
                                                                        ? 'var(--success)'
                                                                        : a.status ===
                                                                          ACCOUNT_STATUS.INACTIVE
                                                                        ? 'var(--warn)'
                                                                        : a.status ===
                                                                          ACCOUNT_STATUS.DISABLED_MAX_WIN
                                                                        ? '#cd672c'
                                                                        : a.status ===
                                                                          ACCOUNT_STATUS.ARCHIVED
                                                                        ? 'black'
                                                                        : 'var(--danger)'
                                                                }
                                                                size={18}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        <a
                                                            href={a.website}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {a.website}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        {a.accountProviders
                                                            .map(
                                                                (ap) =>
                                                                    ap.provider
                                                                        .name
                                                            )
                                                            .join(', ')}
                                                    </td>
                                                    <td>{a.source}</td>
                                                    <td>{a.subSource}</td>
                                                    <td>{a.username}</td>
                                                    <td>{a.password}</td>
                                                    <td>{a.family}</td>
                                                    <td>
                                                        {a.proxyState
                                                            ? `${a.proxyState}, `
                                                            : ''}
                                                        {a.proxyCity}
                                                    </td>
                                                    <td>
                                                        {formatNumber(
                                                            (a.liability || 0) *
                                                                100
                                                        )}
                                                    </td>
                                                    <td>{a.autoBetAmount}</td>
                                                    <td>{a.winTotal ?? '-'}</td>
                                                    <td
                                                        title={getAccountBetTypes(
                                                            a.supportedBetTypes,
                                                            a.supportedBetIntervals,
                                                            a.supportedBetLeagues
                                                        )}
                                                    >
                                                        {getAccountBetTypes(
                                                            a.supportedBetTypes,
                                                            a.supportedBetIntervals,
                                                            a.supportedBetLeagues
                                                        )}
                                                    </td>
                                                    <td>
                                                        {a.rebet ? 'Yes' : 'No'}
                                                    </td>
                                                    <td
                                                        title={a.tags.join(
                                                            ', '
                                                        )}
                                                    >
                                                        {a.tags.join(', ')}
                                                    </td>
                                                    <td>{a.currency ?? ''}</td>
                                                    <td>
                                                        {a.lastCompletedBet &&
                                                            new Date(
                                                                a.lastCompletedBet
                                                            ).toLocaleString()}
                                                    </td>
                                                    <td>
                                                        {a.lastAttemptedBet &&
                                                            new Date(
                                                                a.lastAttemptedBet
                                                            ).toLocaleString()}
                                                    </td>
                                                    <td
                                                        className={
                                                            styles.sticky
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.icons
                                                            }
                                                        >
                                                            <TbEyeSearch
                                                                className={
                                                                    styles.icon
                                                                }
                                                                size={18}
                                                                onClick={() =>
                                                                    setAccountModalAction(
                                                                        {
                                                                            account:
                                                                                a,
                                                                            action: 'VIEW_LOGS',
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                            {a.status ===
                                                            ACCOUNT_STATUS.ACTIVE ? (
                                                                <MdOutlinePause
                                                                    className={
                                                                        styles.icon
                                                                    }
                                                                    size={18}
                                                                    onClick={async () => {
                                                                        await toggleAccountStatus(
                                                                            a.id,
                                                                            ACCOUNT_STATUS.INACTIVE
                                                                        )
                                                                        a.status =
                                                                            ACCOUNT_STATUS.INACTIVE
                                                                    }}
                                                                />
                                                            ) : (
                                                                <MdPlayArrow
                                                                    className={
                                                                        styles.icon
                                                                    }
                                                                    size={18}
                                                                    onClick={async () => {
                                                                        await toggleAccountStatus(
                                                                            a.id,
                                                                            ACCOUNT_STATUS.ACTIVE
                                                                        )
                                                                        a.status =
                                                                            ACCOUNT_STATUS.ACTIVE
                                                                    }}
                                                                />
                                                            )}
                                                            <MdOutlineEdit
                                                                className={
                                                                    styles.icon
                                                                }
                                                                size={18}
                                                                onClick={() =>
                                                                    setAccountToEdit(
                                                                        a
                                                                    )
                                                                }
                                                            />
                                                            <MdOutlineDelete
                                                                className={
                                                                    styles.icon
                                                                }
                                                                size={18}
                                                                onClick={() =>
                                                                    setAccountModalAction(
                                                                        {
                                                                            account:
                                                                                a,
                                                                            action: 'DELETE',
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p>No accounts found.</p>
                        )}
                    </div>
                </div>
            ) : (
                <EditAccount
                    account={accountToEdit}
                    setAccount={setAccountToEdit}
                    sources={
                        Array.from(
                            new Set(accountsToShow?.map((a) => a.source))
                        ).filter((s) => s) as string[]
                    }
                    subSources={
                        Array.from(
                            new Set(accountsToShow?.map((a) => a.subSource))
                        ).filter((s) => s) as string[]
                    }
                    families={
                        Array.from(
                            new Set(accountsToShow?.map((a) => a.family))
                        ).filter((s) => s) as string[]
                    }
                    states={
                        Array.from(
                            new Set(accountsToShow?.map((a) => a.proxyState))
                        ).filter((s) => s) as string[]
                    }
                    cities={
                        Array.from(
                            new Set(
                                accountsToShow
                                    ?.map((a) => a.proxyCity)
                                    .concat(supportedProxyCities)
                            )
                        ).filter((s) => s) as string[]
                    }
                />
            )}
            {accountModalAction &&
                accountModalAction.action === 'VIEW_LOGS' && (
                    <AccountActivityModal
                        logs={accountModalAction.account.activity}
                        onClose={() => setAccountModalAction(null)}
                    />
                )}
            {accountModalAction && accountModalAction.action === 'DELETE' && (
                <AccountModal
                    account={accountModalAction.account}
                    onClose={() => setAccountModalAction(null)}
                    onConfirm={() =>
                        onConfirmDeleteAccount(accountModalAction.account.id)
                    }
                    action="Delete"
                />
            )}
            {showAccountsDownloadModal && accountsToShow && (
                <AccountDownloadModal
                    accounts={formatDownload(accountsToShow)}
                    onClose={() => setShowAccountDownModal(false)}
                />
            )}
        </div>
    )
}
