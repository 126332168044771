import React from 'react'
import styles from './RestartButton.module.css'

export const RestartButton = (props: { mouseDown: () => void }) => {
    const { mouseDown } = props
    const [disabled, setDisabled] = React.useState<boolean>(false)

    return (
        <button
            disabled={disabled}
            onMouseDown={() => {
                setDisabled(true)
                mouseDown()
                setTimeout(() => setDisabled(false), 3000)
            }}
            className={styles.restart_button}
        >
            Restart
        </button>
    )
}
