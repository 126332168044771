import { Modal } from '../../../components/Modal'
import { UTCToEST } from '../../../utils/timezone'
import { AccountActivityIF } from '../types'
import styles from '../../../App.module.css'

export const AccountActivityModal = (props: {
    logs: AccountActivityIF[]
    onClose: () => void
}) => {
    const { logs, onClose } = props
    return (
        <Modal onClose={onClose}>
            <div className={styles.modal}>
                <span>Account Activity</span>
                {logs && logs.length > 0 ? (
                    logs
                        .sort((a, b) =>
                            String(a.createdAt).localeCompare(
                                String(b.createdAt)
                            )
                        )
                        .map((log, i) => (
                            <div key={i} className={styles.log}>
                                <p>{`${log.activity} on ${UTCToEST(
                                    log.createdAt
                                )}`}</p>
                            </div>
                        ))
                ) : (
                    <p>No activity found.</p>
                )}
                <div className={styles.modal_button_row}>
                    <button onClick={onClose} type="button">
                        Close
                    </button>
                </div>
            </div>
        </Modal>
    )
}
