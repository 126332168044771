import { useCallback, useEffect, useState } from 'react'
import styles from './BetHistory.module.css'
import Loader from 'react-spinners/BounceLoader'
import { getNotifications } from '../../services/api'
import { NotificationIF } from './types'

const NotificationCard = (props: { notification: NotificationIF }) => {
    const { notification } = props
    return (
        <div className={styles.card}>
            <div className={styles.card_line}>
                <p>{notification.message}</p>
                <p style={{ minWidth: 'max-content' }}>
                    {new Intl.DateTimeFormat('en-US', {
                        month: 'short',
                        day: '2-digit',
                    }).format(new Date(notification.date))}
                </p>
            </div>
        </div>
    )
}

export const Notifications = () => {
    const [notifications, setNotifications] = useState<NotificationIF[]>([])
    const [page, setPage] = useState<number | null>(1)
    const [isLoading, setIsLoading] = useState(false)

    const getNotificationsByPage = useCallback(async (page: number) => {
        setIsLoading(true)
        const notifications = await getNotifications(page)
        if (!notifications || notifications.length === 0) {
            setPage(null)
            setIsLoading(false)
            return
        }

        setNotifications((n) => [
            // prevent double for React strict mode
            ...(page !== 1 ? n : []),
            ...notifications,
        ])
        setPage((p) => p && p + 1)
        setIsLoading(false)
    }, [])
    useEffect(() => {
        getNotificationsByPage(1)
    }, [getNotificationsByPage])

    const isWithin24Hours = (date: Date) =>
        (new Date().getTime() - new Date(date).getTime()) / (1000 * 60 * 60) <=
        24

    return (
        <div className={styles.history}>
            <header>Notifications</header>
            <div className={styles.scrollable}>
                <p>Within the last 24 hours</p>
                {notifications.length > 0 &&
                    notifications.map((n, i) => (
                        <>
                            {((i === 0 &&
                                !isWithin24Hours(notifications[i].date)) ||
                                (i !== 0 &&
                                    isWithin24Hours(
                                        notifications[i - 1].date
                                    ) &&
                                    !isWithin24Hours(
                                        notifications[i].date
                                    ))) && <p>Past Notifications</p>}
                            <NotificationCard key={i} notification={n} />
                        </>
                    ))}
                {isLoading ? (
                    <div className={styles.loading}>
                        <Loader
                            loading={isLoading}
                            size={page === 1 ? 48 : 24}
                            color={'var(--dark2)'}
                        />
                    </div>
                ) : (
                    <>
                        {page && notifications.length % 25 === 0 && (
                            <p
                                className={styles.show_more}
                                onClick={() => getNotificationsByPage(page)}
                            >
                                Show More
                            </p>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
