import { CiFootball, CiBaseball } from 'react-icons/ci'
import { LiaHockeyPuckSolid, LiaBasketballBallSolid } from 'react-icons/lia'
import { PiSoccerBallLight, PiTennisBall } from 'react-icons/pi'
import icon from '../../../assets/icon.png'
import styles from './Sidebar.module.css'
import { useCallback } from 'react'

interface SportIF {
    sport: string
    leagues: string[]
    selectedLeague: string
    setSelectedLeague: (val: string) => void
}

export const Sport = (props: SportIF) => {
    const { sport, leagues, selectedLeague, setSelectedLeague } = props

    const sportIcon = ((label: string) => {
        if (label.toLowerCase().includes('football'))
            return <CiFootball size={18} />
        if (label.toLowerCase().includes('baseball'))
            return <CiBaseball size={18} />
        if (label.toLowerCase().includes('hockey'))
            return <LiaHockeyPuckSolid size={18} />
        if (label.toLowerCase().includes('basketball'))
            return <LiaBasketballBallSolid size={18} />
        if (label.toLowerCase().includes('soccer'))
            return <PiSoccerBallLight size={18} />
        if (label.toLowerCase().includes('tennis'))
            return <PiTennisBall size={18} />
        return <img src={icon} alt="betnet" width={18} />
    })(sport)

    const onLeagueClick = useCallback(
        (league: string) => {
            setSelectedLeague(league)
        },
        [setSelectedLeague]
    )

    const League = useCallback(
        (props: { league: string }) => {
            const { league } = props
            return (
                <div
                    className={`${styles.button} ${styles.p} ${
                        selectedLeague === league && styles.selected
                    } ${styles.league}`}
                    role="button"
                    onClick={() => onLeagueClick(league)}
                >
                    {league}
                </div>
            )
        },
        [onLeagueClick, selectedLeague]
    )

    return (
        <div className={styles.sport}>
            <div className={styles.p}>
                {sportIcon}
                {sport}
            </div>

            {leagues
                .sort((a, b) => a.localeCompare(b))
                .map((league, index) => (
                    <League league={league} key={index} />
                ))}
        </div>
    )
}
