import { useContext } from 'react'
import { MainBettor } from '../bets/Bet'
import Loader from 'react-spinners/BounceLoader'
import styles from '../bets/Bet.module.css'
import { Context } from '../../utils/context'
import { UserRoles } from '../login/types'
import { Reports } from '../reports/Reports'

export const Main = () => {
    const { loggedInUser } = useContext(Context)

    if (loggedInUser?.role === UserRoles.BETTOR) {
        return <MainBettor />
    } else if (loggedInUser?.role === UserRoles.ADMIN) {
        return <Reports />
    } else {
        return (
            <div className={styles.loading}>
                <Loader loading={true} size={48} color={'var(--dark1)'} />
            </div>
        )
    }
}
