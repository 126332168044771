import { FiDownload } from 'react-icons/fi'
import styles from '../Reports.module.css'
import { Dispatch, SetStateAction } from 'react'
import { ReportOverviewIF } from '../types'

export const ReportOverview = (props: {
    overview?: ReportOverviewIF
    setShowDownloadModal: Dispatch<SetStateAction<boolean>>
}) => {
    const { overview, setShowDownloadModal } = props

    return (
        <div className={styles.sidebar}>
            <div className={styles.sidebar_content}>
                <div>
                    <p>Attempted Bets</p>
                    <p>{overview?.attempted || '-'}</p>
                </div>
                <div>
                    <p>Unique Bets</p>
                    <p>{overview?.unique || '-'}</p>
                </div>
                <div>
                    <p>Placed Bets</p>
                    <p>{overview?.placed || '-'}</p>
                </div>
                <div style={{ paddingTop: '0.5rem' }}>
                    <p>Bets Won</p>
                    <p>{overview?.won || '-'}</p>
                </div>
                <div>
                    <p>Bets Lost</p>
                    <p>{overview?.lost ?? '-'}</p>
                </div>
                <div style={{ paddingTop: '0.5rem' }}>
                    <p>Total Won</p>
                    <p style={{ color: 'var(--success)' }}>{`$${(
                        overview?.amountWon || 0
                    ).toFixed(2)}`}</p>
                </div>
                <div>
                    <p>Total Lost</p>
                    <p style={{ color: 'var(--danger)' }}>{`$${(
                        overview?.amountLost || 0
                    ).toFixed(2)}`}</p>
                </div>
                <div style={{ paddingTop: '0.5rem' }}>
                    <p>Net</p>
                    <p
                        style={{
                            color:
                                !overview ||
                                overview?.amountWon === overview?.amountLost
                                    ? 'var(--text1)'
                                    : overview?.amountWon > overview?.amountLost
                                    ? 'var(--success)'
                                    : 'var(--danger)',
                        }}
                    >
                        {`$${(overview?.net || 0).toFixed(2)}`}
                    </p>
                </div>
                <div>
                    <p>Net Liable</p>
                    <p
                        style={{
                            color:
                                !overview ||
                                overview?.amountWon === overview?.amountLost
                                    ? 'var(--text1)'
                                    : overview?.amountWon > overview?.amountLost
                                    ? 'var(--success)'
                                    : 'var(--danger)',
                        }}
                    >
                        {`$${(overview?.netLiable || 0).toFixed(2)}`}
                    </p>
                </div>
            </div>
            <div className={styles.sidebar_content}>
                <button
                    className={styles.download}
                    onClick={() => setShowDownloadModal(true)}
                >
                    <FiDownload size={24} /> Download Report
                </button>
            </div>
        </div>
    )
}
