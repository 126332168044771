export interface LoginPayloadIF {
    token: string
    userId: number
    role: string
    email?: string
}

export enum UserRoles {
    ADMIN = 'ADMIN',
    BETTOR = 'BETTOR',
}
