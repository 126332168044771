import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { IoMdClose } from 'react-icons/io'
import { Dropdown } from '../../../components/Dropdown'
import {
    getProviders,
    createAccount,
    updateAccount,
} from '../../../services/api'
import styles from '../../../App.module.css'
import { formatNumber } from '../../bets/helpers'
import {
    SUPPORTED_BET_TYPES,
    SUPPORTED_BET_INTERVALS,
    SUPPORTED_BET_LEAGUES,
} from '../helpers'
import { AccountIF, ProviderIF } from '../types'

export const EditAccount = (props: {
    subSources: string[]
    account: AccountIF
    setAccount: Dispatch<SetStateAction<AccountIF | null>>
    sources: string[]
    states: string[]
    families: string[]
    cities: string[]
}) => {
    const { account, setAccount } = props
    const [providers, setProviders] = useState<ProviderIF[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const isExistingAccount = !!account.id

    useEffect(() => {
        // populate default values for new account
        if (!isExistingAccount)
            setAccount({
                supportedBetTypes: SUPPORTED_BET_TYPES,
                supportedBetIntervals: SUPPORTED_BET_INTERVALS,
                supportedBetLeagues: SUPPORTED_BET_LEAGUES,
            } as AccountIF)
        ;(async () => {
            const res = await getProviders()
            if (res?.providers) setProviders(res?.providers)
        })()
    }, [isExistingAccount, setAccount])

    const onSubmit = async () => {
        setIsLoading(true)
        try {
            if (!isExistingAccount) {
                await createAccount(account)
            } else {
                await updateAccount(account)
            }
            setAccount(null)
        } catch (e: any) {
            setError(
                e.message ||
                    `Unable to ${
                        isExistingAccount ? 'edit' : 'create'
                    } account. Please try again later.`
            )
        }
        setIsLoading(false)
    }

    return (
        <>
            <div className={styles.section_header}>
                {`${isExistingAccount ? 'Edit' : 'Add'} Account`}
                <IoMdClose
                    size={24}
                    className={styles.close}
                    onClick={() => setAccount(null)}
                />
            </div>
            <div className={styles.section_content} style={{ padding: '1rem' }}>
                <div className={styles.flex_container}>
                    <label>Website</label>
                    <input
                        type="text"
                        value={account.website}
                        onChange={(e) =>
                            setAccount({ ...account, website: e.target.value })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Username</label>
                    <input
                        type="text"
                        value={account.username}
                        onChange={(e) =>
                            setAccount({ ...account, username: e.target.value })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Password</label>
                    <input
                        type="text"
                        value={account.password}
                        onChange={(e) =>
                            setAccount({
                                ...account,
                                password: e.target.value,
                            })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Provider</label>
                    <Dropdown
                        value={
                            account.accountProviders?.map(
                                (ap) => ap.provider.name
                            ) ?? undefined
                        }
                        options={providers.map((p) => p.name)}
                        onMultiSelect={(option) => {
                            if (!option) return
                            setAccount({
                                ...account,
                                accountProviders: option.map((o) => ({
                                    account,
                                    provider: providers.find(
                                        (p) => p.name === o
                                    ) ?? { id: 0, name: '' },
                                })),
                            })
                        }}
                        placeholder="Select a provider..."
                        search
                        multiple
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Family</label>
                    <Dropdown
                        value={account.family ?? undefined}
                        creatable
                        options={props.families}
                        onSelect={(option) =>
                            setAccount({
                                ...account,
                                family: option,
                            })
                        }
                        search
                        clear
                        placeholder="Select a family"
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Tags (separate by comma)</label>
                    <input
                        type="text"
                        value={account.tags && account.tags.join(', ')}
                        onChange={(e) =>
                            setAccount({
                                ...account,
                                tags: e.target.value
                                    .split(',')
                                    .map((t) => t.trim()),
                            })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Source</label>
                    <Dropdown
                        value={account.source ?? undefined}
                        options={props.sources}
                        onSelect={(option) =>
                            setAccount({
                                ...account,
                                source: option,
                            })
                        }
                        placeholder="Select a source..."
                        search
                        creatable
                        clear
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Sub-Source</label>
                    <Dropdown
                        value={account.subSource ?? undefined}
                        options={props.subSources}
                        onSelect={(option) =>
                            setAccount({
                                ...account,
                                subSource: option,
                            })
                        }
                        placeholder="Select a sub-source..."
                        search
                        creatable
                        clear
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>State</label>
                    <Dropdown
                        value={account.proxyState ?? undefined}
                        options={props.states.sort((a, b) =>
                            a.localeCompare(b)
                        )}
                        onSelect={(option) => {
                            setAccount({
                                ...account,
                                proxyState: option,
                            })
                        }}
                        placeholder="Start typing to select a state..."
                        search
                        creatable
                        clear
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>City</label>
                    <Dropdown
                        value={account.proxyCity ?? undefined}
                        options={props.cities.sort((a, b) =>
                            a.localeCompare(b)
                        )}
                        onSelect={(option) => {
                            setAccount({
                                ...account,
                                proxyCity: option,
                            })
                        }}
                        placeholder="Start typing to select a city..."
                        search
                        creatable
                        clear
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Liability %</label>
                    <input
                        type="text"
                        value={
                            account.liability !== undefined
                                ? formatNumber(account.liability * 100)
                                : ''
                        }
                        onChange={(e) =>
                            (!e.target.value ||
                                (!isNaN(parseFloat(e.target.value)) &&
                                    parseFloat(e.target.value) <= 100)) &&
                            setAccount({
                                ...account,
                                liability:
                                    e.target.value === ''
                                        ? undefined
                                        : parseFloat(e.target.value) / 100,
                            })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Autobet Amount</label>
                    <input
                        type="text"
                        value={
                            account.autoBetAmount !== 0
                                ? account.autoBetAmount
                                : ''
                        }
                        onChange={(e) =>
                            (!e.target.value ||
                                !isNaN(parseFloat(e.target.value))) &&
                            setAccount({
                                ...account,
                                autoBetAmount:
                                    e.target.value === ''
                                        ? 0
                                        : parseFloat(e.target.value),
                            })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Max Win Amount</label>
                    <input
                        type="text"
                        value={
                            account.winTotal && account.winTotal !== 0
                                ? account.winTotal
                                : ''
                        }
                        onChange={(e) =>
                            (!e.target.value ||
                                !isNaN(parseFloat(e.target.value))) &&
                            setAccount({
                                ...account,
                                winTotal:
                                    e.target.value === ''
                                        ? undefined
                                        : parseFloat(e.target.value),
                            })
                        }
                    />
                </div>
                <div className={styles.flex_container}>
                    <label>Currency</label>
                    <Dropdown
                        value={account.currency ?? 'USD'}
                        options={['USD', 'CAD']}
                        onSelect={(option) => {
                            setAccount({
                                ...account,
                                currency: option,
                            })
                        }}
                        clear
                    />
                </div>
                <div className={`${styles.flex_container} ${styles.checkbox}`}>
                    <label>Bet Types</label>
                    <div className={styles.checkbox_container}>
                        {SUPPORTED_BET_TYPES.map((type) => (
                            <label key={type}>
                                <input
                                    type="checkbox"
                                    checked={account.supportedBetTypes?.includes(
                                        type
                                    )}
                                    onChange={(e) =>
                                        setAccount({
                                            ...account,
                                            supportedBetTypes: e.target.checked
                                                ? [
                                                      ...account.supportedBetTypes,
                                                      type,
                                                  ]
                                                : account.supportedBetTypes.filter(
                                                      (t) => t !== type
                                                  ),
                                        })
                                    }
                                />
                                <span>{type}</span>
                            </label>
                        ))}
                    </div>
                </div>
                <div className={`${styles.flex_container} ${styles.checkbox}`}>
                    <label>Bet Intervals</label>
                    <div className={styles.checkbox_container}>
                        {SUPPORTED_BET_INTERVALS.map((interval) => (
                            <label key={interval}>
                                <input
                                    type="checkbox"
                                    checked={account.supportedBetIntervals?.includes(
                                        interval
                                    )}
                                    onChange={(e) =>
                                        setAccount({
                                            ...account,
                                            supportedBetIntervals: e.target
                                                .checked
                                                ? [
                                                      ...account.supportedBetIntervals,
                                                      interval,
                                                  ]
                                                : account.supportedBetIntervals.filter(
                                                      (t) => t !== interval
                                                  ),
                                        })
                                    }
                                />
                                <span>
                                    {interval === 'Segment'
                                        ? 'Quarter/Period'
                                        : interval}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>
                <div className={`${styles.flex_container} ${styles.checkbox}`}>
                    <label>Bet Leagues</label>
                    <div className={styles.checkbox_container}>
                        {SUPPORTED_BET_LEAGUES.map((league) => (
                            <label key={league}>
                                <input
                                    type="checkbox"
                                    checked={account.supportedBetLeagues?.includes(
                                        league
                                    )}
                                    onChange={(e) =>
                                        setAccount({
                                            ...account,
                                            supportedBetLeagues: e.target
                                                .checked
                                                ? [
                                                      ...account.supportedBetLeagues,
                                                      league,
                                                  ]
                                                : account.supportedBetLeagues.filter(
                                                      (t) => t !== league
                                                  ),
                                        })
                                    }
                                />
                                <span>{league}</span>
                            </label>
                        ))}
                    </div>
                </div>
                <div className={styles.checkbox}>
                    <label>
                        <input
                            type="checkbox"
                            checked={account.rebet}
                            onChange={() =>
                                setAccount({
                                    ...account,
                                    rebet: !account.rebet,
                                })
                            }
                        />
                        <span>Rebet</span>
                    </label>
                </div>
                <div className={styles.checkbox}>
                    <label>
                        <input
                            type="checkbox"
                            checked={account.isScraperAccount}
                            onChange={() =>
                                setAccount({
                                    ...account,
                                    isScraperAccount: !account.isScraperAccount,
                                })
                            }
                        />
                        <span>Scraper Account</span>
                    </label>
                </div>
                <div className={styles.flex_container}>
                    {error && <span className={styles.error}>{error}</span>}
                    <button
                        onClick={onSubmit}
                        disabled={
                            isLoading ||
                            (!isExistingAccount &&
                                (!account.website ||
                                    !account.username ||
                                    !account.password ||
                                    !account.accountProviders ||
                                    account.accountProviders.length === 0 ||
                                    !account.autoBetAmount))
                        }
                    >
                        {isLoading ? '...' : isExistingAccount ? 'Save' : 'Add'}
                    </button>
                </div>
            </div>
        </>
    )
}
