import { GameIF } from '../bets/types'

export interface SettingsIF {
    scrapersActive: boolean
    bettorsActive: boolean
}

export enum DropletStatus {
    ACTIVE = 'ACTIVE',
    STARTING = 'STARTING',
    STOPPING = 'STOPPING',
}

export interface ScraperStatusIF {
    dropletId: string
    dropletStatus: DropletStatus | null
    healthchecksMissed: number
    scrapesMissed?: number
}

export type GameScraperIF = GameIF & ScraperStatusIF

export interface SettingsWHIF {
    scrapersActive: boolean
    bettorsActive: boolean
}

export type EnabledScraperSettingsIF = {
    providers: number[]
    sports: string[]
}
