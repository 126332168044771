import { useEffect, useState } from 'react'
import { BiChevronUp, BiChevronDown } from 'react-icons/bi'
import styles from './BetHistory.module.css'
import {
    formatTeam,
    formatNumber,
    getRiskAmount,
    getOutcomeAmount,
} from '../bets/helpers'
import { BetIF } from '../bets/types'
export const BetHistoryCard = (props: { bets: BetIF[] }) => {
    const { bets } = props
    const [isStatusOpen, setIsStatusOpen] = useState(false)
    const [gameString, setGameString] = useState('...')
    const completedBets = bets.filter((b) => b.status === 'COMPLETED')
    const pendingBets = bets.filter((b) => b.status === 'PENDING')
    const failedBets = bets.filter((b) => b.status === 'ERROR')
    const statusArr = [
        ...(completedBets.length > 0
            ? [
                  <span key="completed" style={{ color: 'var(--success)' }}>
                      {completedBets.length} completed
                  </span>,
              ]
            : []),
        ...(pendingBets.length > 0
            ? [
                  <span key="pending" style={{ color: 'var(--warn)' }}>
                      {pendingBets.length} pending
                  </span>,
              ]
            : []),
        ...(failedBets.length > 0
            ? [
                  <span key="failed" style={{ color: 'var(--danger)' }}>
                      {failedBets.length} failed
                  </span>,
              ]
            : []),
    ]

    useEffect(() => {
        if (gameString === '...') {
            const game = bets.find((b) => b.odds.game)
            if (game) {
                setGameString(
                    formatTeam(game.odds.game.awayTeam, game.odds.game.homeTeam)
                )
            }
        }
    }, [bets, gameString])

    return (
        <div className={styles.card}>
            <div className={styles.card_line}>
                <div title={gameString}>{gameString}</div>

                <div>
                    {' '}
                    {bets[0].isAutobet
                        ? 'Autobet'
                        : `$${formatNumber(
                              bets[0].riskAmount ?? getRiskAmount(bets[0])
                          )} / bet`}
                </div>
            </div>
            <div className={styles.card_line}>
                <div
                    title={`${bets[0].odds.category} ${bets[0].odds.group}`}
                >{`${bets[0].odds.category} ${bets[0].odds.group}`}</div>
                <div>{`$${formatNumber(
                    completedBets.reduce(
                        (runningTotal, cur) =>
                            runningTotal +
                            (cur.riskAmount ?? getRiskAmount(cur)),
                        0
                    )
                )} at risk`}</div>
            </div>
            <div className={styles.card_line}>
                <div>{bets[0].odds.description}</div>
                <div>{`To win $${formatNumber(
                    completedBets.reduce(
                        (runningTotal, cur) =>
                            runningTotal + getOutcomeAmount(cur),
                        0
                    )
                )}`}</div>
            </div>
            <div>
                <div
                    className={styles.card_status}
                    onClick={() => setIsStatusOpen(!isStatusOpen)}
                >
                    <div className={styles.card_header}>
                        Details
                        {isStatusOpen ? (
                            <BiChevronUp
                                className={styles.card_arrow}
                                size={20}
                            />
                        ) : (
                            <BiChevronDown
                                className={styles.card_arrow}
                                size={20}
                            />
                        )}
                    </div>
                    {!isStatusOpen && (
                        <div className={styles.card_status_summary}>
                            {statusArr.reduce<React.ReactNode[]>(
                                (prev, curr, index) =>
                                    index === 0
                                        ? [curr]
                                        : [
                                              prev,
                                              <span key={index}>,&nbsp;</span>,
                                              curr,
                                          ],
                                []
                            )}
                        </div>
                    )}
                    {isStatusOpen && (
                        <div className={styles.card_status_details}>
                            <div className={styles.card_line}>
                                <div>Original Odds</div>
                                <div>
                                    {bets[0].odds.line > 0 && '+'}
                                    {bets[0].odds.line}
                                </div>
                            </div>
                            {completedBets.map((b, index) => (
                                <div
                                    className={styles.card_line}
                                    key={`${index}`}
                                >
                                    <div style={{ color: 'var(--success)' }}>
                                        {b.account.website}
                                    </div>
                                    <div>
                                        $
                                        {formatNumber(
                                            b.riskAmount ?? getRiskAmount(b)
                                        )}{' '}
                                        @{' '}
                                        {b.realOddsLine &&
                                            b.realOddsLine > 0 &&
                                            '+'}
                                        {b.realOddsLine}
                                    </div>
                                </div>
                            ))}
                            {pendingBets.map((b, index) => (
                                <div
                                    className={styles.card_line}
                                    key={`${index}`}
                                >
                                    <div style={{ color: 'var(--warn)' }}>
                                        {b.account.website}
                                    </div>
                                </div>
                            ))}
                            {failedBets.map((b, index) => (
                                <div
                                    className={styles.card_line}
                                    key={`${index}`}
                                >
                                    <div style={{ color: 'var(--danger)' }}>
                                        {b.account.website}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
