import { Modal } from '../../../components/Modal'
import { AccountIF } from '../types'
import styles from '../../../App.module.css'

export const AccountModal = (props: {
    account: AccountIF
    onClose: () => void
    onConfirm: () => void
    action: string
}) => {
    const { account, onClose, onConfirm, action } = props
    return (
        <Modal onClose={onClose}>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                }}
            >
                <div className={styles.modal}>
                    <p>{`Are you sure you want to ${action.toLowerCase()} account ${
                        account.username
                    } on ${account.website}?`}</p>
                    <div className={styles.modal_button_row}>
                        <button onClick={onClose} type="button">
                            Cancel
                        </button>
                        <button
                            className={styles.danger}
                            onClick={onConfirm}
                            type="submit"
                        >
                            {action}
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}
