import { ScraperStatusIF } from '../settings/types'

export interface ProviderIF {
    id: number
    name: string
    scraperProviderId?: number
}

export enum ACCOUNT_STATUS {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DISABLED_MAX_WIN = 'DISABLED_MAX_WIN',
    ERROR = 'ERROR',
    LOGIN_ERROR = 'LOGIN_ERROR',
    ARCHIVED = 'ARCHIVED',
}

export interface AccountProviderIF {
    providerId?: number
    accountId?: number
    id?: number
    account: AccountIF
    provider: ProviderIF
}

export interface AccountProviderScraperIF
    extends AccountProviderIF,
        ScraperStatusIF {}

export interface AccountIF {
    id: number
    username: string
    password: string
    website: string
    tags: string[]
    proxyCity?: string
    proxyState?: string
    accountProviders: AccountProviderIF[]
    autoBetAmount: number
    winTotal?: number
    liability?: number
    isScraperAccount: boolean
    source?: string
    status: ACCOUNT_STATUS
    supportedBetTypes: string[]
    supportedBetIntervals: string[]
    supportedBetLeagues: string[]
    currency?: string
    family?: string
    subSource?: string
    rebet?: boolean
}
export interface AccountScraperIF extends AccountIF {
    accountProviders: AccountProviderScraperIF[]
}

export interface AccountMetadataIF {
    id: number
    activity: AccountActivityIF[]
    lastCompletedBet?: string
    lastAttemptedBet?: string
}

export interface AccountActivityIF {
    activity: string
    createdAt: Date
}

export type AccountWithMetadataIF = AccountScraperIF & AccountMetadataIF
