import { useContext, useEffect, useMemo, useState } from 'react'
import { Context } from '../../utils/context'
import styles from '../../App.module.css'
import Loader from 'react-spinners/BounceLoader'
import {
    getProviders,
    getUserEnabledGameSettings,
    setGameEnabled,
    setUserEnabledGameSettings,
} from '../../services/api'
import { ProviderIF } from '../accounts/types'
import { CanonicalSportNamesIF } from '../bets/types'
import { EnabledScraperSettingsIF } from './types'

const CanonicalSports: Array<CanonicalSportNamesIF> = [
    'Football',
    'Basketball',
    'Baseball',
    'Hockey',
    'Soccer',
    'Tennis',
    'Golf',
]

export const Games = () => {
    const { games } = useContext(Context)
    const [search, setSearch] = useState('')

    const gamesToShow = useMemo(() => {
        return games.filter((g) => {
            if (search === '') return true
            const regex = new RegExp(`${search}`, 'i')

            return [
                g.sport,
                g.league,
                g.homeTeam,
                g.awayTeam,
                g.provider.name,
            ].some((x) => regex.test(x))
        })
    }, [games, search])

    const [providers, setProviders] = useState<ProviderIF[]>([])
    const [isLoadingSettings, setIsLoadingSettings] = useState(false)
    const [enabledSettings, setEnabledSettings] =
        useState<EnabledScraperSettingsIF>({
            providers: [],
            sports: [],
        })

    useEffect(() => {
        ;(async () => {
            const providers = await getProviders()
            if (providers) {
                setProviders(providers.providers)
            }
        })()
    }, [])

    useEffect(() => {
        setIsLoadingSettings(true)
        ;(async () => {
            try {
                const settings = await getUserEnabledGameSettings()
                if (settings) {
                    setEnabledSettings(settings)
                }
            } catch (e) {
                console.error(e)
            }
            setIsLoadingSettings(false)
        })()
    }, [])

    const updateSettings = async (settings: EnabledScraperSettingsIF) => {
        setIsLoadingSettings(true)
        try {
            await setUserEnabledGameSettings(settings)

            const latest = await getUserEnabledGameSettings()
            if (latest) {
                setEnabledSettings(latest)
            }
        } catch (e) {
            console.error(e)
        }

        setIsLoadingSettings(false)
    }

    return (
        <div className={styles.settings}>
            <div className={styles.header}>
                <header>Game Management</header>
            </div>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div className={styles.section}>
                    <div className={styles.section_header}>
                        <div className={styles.title_container}>Providers</div>
                    </div>
                    <div className={styles.section_content}>
                        {isLoadingSettings ? (
                            <Loader
                                loading={isLoadingSettings}
                                size={48}
                                color={'var(--dark1)'}
                            />
                        ) : gamesToShow && gamesToShow.length > 0 ? (
                            <div className={styles.table_container}>
                                <table className={styles.table}>
                                    <tbody className={styles.scrollable}>
                                        {providers.map((provider) => (
                                            <tr key={provider.id}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={enabledSettings.providers.includes(
                                                            provider.id
                                                        )}
                                                        onChange={async () => {
                                                            await updateSettings(
                                                                {
                                                                    ...enabledSettings,
                                                                    providers:
                                                                        enabledSettings.providers.includes(
                                                                            provider.id
                                                                        )
                                                                            ? enabledSettings.providers.filter(
                                                                                  (
                                                                                      x
                                                                                  ) =>
                                                                                      x !==
                                                                                      provider.id
                                                                              )
                                                                            : [
                                                                                  ...enabledSettings.providers,
                                                                                  provider.id,
                                                                              ],
                                                                }
                                                            )
                                                        }}
                                                    />
                                                </td>
                                                <td className={styles.max}>
                                                    {provider.name}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p>No games found.</p>
                        )}
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.section_header}>
                        <div className={styles.title_container}>Sports</div>
                    </div>
                    <div className={styles.section_content}>
                        {isLoadingSettings ? (
                            <Loader
                                loading={isLoadingSettings}
                                size={48}
                                color={'var(--dark1)'}
                            />
                        ) : gamesToShow && gamesToShow.length > 0 ? (
                            <div className={styles.table_container}>
                                <table className={styles.table}>
                                    <tbody className={styles.scrollable}>
                                        {CanonicalSports.map((sport) => (
                                            <tr key={sport}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={enabledSettings.sports.includes(
                                                            sport
                                                        )}
                                                        onChange={async () => {
                                                            await updateSettings(
                                                                {
                                                                    ...enabledSettings,
                                                                    sports: enabledSettings.sports.includes(
                                                                        sport
                                                                    )
                                                                        ? enabledSettings.sports.filter(
                                                                              (
                                                                                  x
                                                                              ) =>
                                                                                  x !==
                                                                                  sport
                                                                          )
                                                                        : [
                                                                              ...enabledSettings.sports,
                                                                              sport,
                                                                          ],
                                                                }
                                                            )
                                                        }}
                                                    />
                                                </td>
                                                <td className={styles.max}>
                                                    {sport}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p>No games found.</p>
                        )}
                    </div>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles.section_header}>
                    <div className={styles.title_container}>Games</div>
                    <input
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search..."
                    />
                </div>
                <div className={styles.section_content}>
                    {gamesToShow && gamesToShow.length > 0 ? (
                        <div className={styles.table_container}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <td></td>

                                        <td>
                                            <span>Sport</span>
                                        </td>

                                        <td>
                                            <span>League</span>
                                        </td>

                                        <td>
                                            <span>Home Team</span>
                                        </td>

                                        <td>
                                            <span>Away Team</span>
                                        </td>
                                        <td>
                                            <span>Provider</span>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody className={styles.scrollable}>
                                    {gamesToShow.map((g) => (
                                        <tr key={g.id}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={g.enabled}
                                                    onChange={async () => {
                                                        // will be some delay before the checkbox updates (after update from webhook)
                                                        await setGameEnabled(
                                                            g.id,
                                                            !g.enabled
                                                        )
                                                    }}
                                                />
                                            </td>
                                            <td>{g.sport}</td>
                                            <td>{g.league}</td>
                                            <td>{g.homeTeam}</td>
                                            <td>{g.awayTeam}</td>
                                            <td>{g.provider.name}</td>
                                            <td className={styles.sticky}>
                                                <div
                                                    className={styles.icons}
                                                ></div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p>No games found.</p>
                    )}
                </div>
            </div>
        </div>
    )
}
