import styles from './Header.module.css'
import Logo from '../../assets/logo.png'
import { useContext, useEffect, useRef, useState } from 'react'
import { LuBell, LuHistory } from 'react-icons/lu'
import { IoSettingsOutline } from 'react-icons/io5'
import { useNavigate, useLocation } from 'react-router-dom'
import { VscAccount, VscJersey } from 'react-icons/vsc'
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { getLastNotification } from '../../services/api'
import { Context } from '../../utils/context'
import { RightSidebar } from './types'

export const Header = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {
        loggedInUser,
        betNotification: newBets,
        setBetNotification,
        isRightSidebarOpen,
        setIsRightSidebarOpen,
    } = useContext(Context)
    const betHistoryButton = useRef<HTMLDivElement>(null)

    const [showNotificationNotification, setShowNotificationNotification] =
        useState(false)
    const notificationsButton = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (loggedInUser)
            (async () => {
                const lastNotification = await getLastNotification()
                setShowNotificationNotification(
                    !!lastNotification &&
                        (new Date().getTime() -
                            new Date(lastNotification.date).getTime()) /
                            (1000 * 60 * 60) <=
                            24
                )
            })()
    }, [loggedInUser])

    useEffect(() => {
        isRightSidebarOpen === RightSidebar.BET_HISTORY &&
            newBets &&
            setBetNotification(false)
    }, [newBets, isRightSidebarOpen, setBetNotification])

    return (
        <>
            <div className={styles.header}>
                <img
                    aria-label="Home"
                    src={Logo}
                    height={48}
                    alt="betnet"
                    className={styles.icons}
                    role="button"
                    onClick={() => location.pathname !== '/' && navigate('/')}
                />
                {loggedInUser && (
                    <div className={styles.icons}>
                        <div
                            aria-label="Reports"
                            className={styles.icons}
                            role="button"
                            onClick={() =>
                                location.pathname !== '/reports' &&
                                navigate('/reports')
                            }
                        >
                            <HiOutlineDocumentReport size={26} />
                        </div>
                        <div
                            aria-label="Accounts"
                            className={styles.icons}
                            role="button"
                            onClick={() =>
                                location.pathname !== '/accounts' &&
                                navigate('/accounts')
                            }
                        >
                            <VscAccount size={24} />
                        </div>
                        <div
                            aria-label="Games"
                            className={styles.icons}
                            role="button"
                            onClick={() =>
                                location.pathname !== '/games' &&
                                navigate('/games')
                            }
                        >
                            <VscJersey size={24} />
                        </div>
                        <div
                            aria-label="Settings"
                            className={`${styles.icons} ${styles.borderRight}`}
                            role="button"
                            onClick={() =>
                                location.pathname !== '/settings' &&
                                navigate('/settings')
                            }
                        >
                            <IoSettingsOutline size={24} />
                        </div>
                        <div
                            aria-label="Notifications"
                            ref={notificationsButton}
                            className={styles.icons}
                            role="button"
                            onClick={() =>
                                setIsRightSidebarOpen((prev) =>
                                    prev === RightSidebar.NOTIFICATION
                                        ? null
                                        : RightSidebar.NOTIFICATION
                                )
                            }
                        >
                            <LuBell size={24} />
                            {showNotificationNotification && (
                                <div className={styles.notification}></div>
                            )}
                        </div>
                        <div
                            aria-label="History"
                            ref={betHistoryButton}
                            className={styles.icons}
                            role="button"
                            onClick={() =>
                                setIsRightSidebarOpen((prev) =>
                                    prev === RightSidebar.BET_HISTORY
                                        ? null
                                        : RightSidebar.BET_HISTORY
                                )
                            }
                        >
                            <LuHistory size={24} />
                            {newBets && (
                                <div className={styles.notification}></div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
