import { useState, useEffect, useContext } from 'react'
import styles from './BetHistory.module.css'
import { BetHistoryCard } from './BetHistoryCard'
import Loader from 'react-spinners/BounceLoader'
import { getBets } from '../../services/api'
import { Context } from '../../utils/context'
import { BetIF } from '../bets/types'

export const BetHistory = () => {
    const { webSocket } = useContext(Context)

    const [bets, setBets] = useState<{ [groupId: string]: BetIF[] }>({})
    const [page, setPage] = useState<number | null>(1)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        ;(async () => {
            if (!page) return
            setIsLoading(true)
            const allBets = await getBets(page)
            if (!allBets || allBets.length === 0) {
                setPage(null)
                setIsLoading(false)
                return
            }

            let groupedBets: { [groupId: string]: BetIF[] } = {}
            allBets.forEach((b) => {
                if (groupedBets[b.groupId]) groupedBets[b.groupId].push(b)
                else groupedBets[b.groupId] = [b]
            })
            setBets((b) => ({ ...b, ...groupedBets }))
            setIsLoading(false)
        })()
    }, [page])

    useEffect(() => {
        if (!webSocket) return

        webSocket.on('bets', (data: BetIF[]) => {
            setBets((prev) => {
                const map = new Map(
                    Object.entries(prev).map(([key, value]) => [
                        key,
                        new Map(value.map((b: any) => [b.id, b])),
                    ])
                )
                data.forEach((b) => {
                    if (!map.has(b.groupId)) {
                        map.set(b.groupId, new Map())
                    }
                    map.get(b.groupId)?.set(b.id, {
                        ...(map.get(b.groupId)?.get(b.id) ?? {}),
                        ...b,
                    })
                })

                return Object.fromEntries(
                    Array.from(map.entries()).map(([key, value]) => [
                        key,
                        Array.from(value.values()).filter(
                            (b: any) => !!b.amount
                        ),
                    ])
                )
            })
        })

        return () => {
            webSocket.off('bets')
        }
    }, [webSocket])

    return (
        <div className={styles.history}>
            <header>Bet History</header>
            <div className={styles.scrollable}>
                {Object.values(bets)
                    .sort((a, b) =>
                        String(b[0].createdAt).localeCompare(
                            String(a[0].createdAt)
                        )
                    )
                    .map((b) => (
                        <BetHistoryCard bets={b} key={b[0].id} />
                    ))}
                {isLoading ? (
                    <div className={styles.loading}>
                        <Loader
                            loading={isLoading}
                            size={page === 1 ? 48 : 24}
                            color={'var(--dark2)'}
                        />
                    </div>
                ) : (
                    <>
                        {page && Object.keys(bets).length % 25 === 0 && (
                            <p
                                className={styles.show_more}
                                onClick={() => setPage(page + 1)}
                            >
                                Show More
                            </p>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
